import React from 'react';
import App from 'modules/App';
import Layout from 'modules/Layout';


function action({ route }) {
  const code = 404;
  return {
    chunks   : ['not-found'],
    name     : route.name,
    component: (
      <Layout.components.PlainLayout>
        <App.components.Error code={code} />
      </Layout.components.PlainLayout>
    ),
    status: code,
  };
}

export default action;
